import React from "react";
import {
  createBrowserRouter,
  RouterProvider,
  Navigate,
  Outlet,
} from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { MainLayout } from "./layouts/MainLayout";
import { AuthLayout } from "./layouts/AuthLayout";
import LoadingScreen from "./components/LoadingScreen/LoadingScreen";

const LoginPage = React.lazy(() => import("./pages/auth/Login"));
const ResetPasswordPage = React.lazy(() => import("./pages/auth/ResetPassword"));
const ChatPage = React.lazy(() => import("./pages/chat/ChatPage"));
const NewChatPage = React.lazy(() => import("./pages/chat/NewChatPage"));
const SettingsPage = React.lazy(() => import("./pages/settings/Settings"));
const TermsOfServicePage = React.lazy(() => import("./pages/legal/TermsOfService"));
const PrivacyPolicyPage = React.lazy(() => import("./pages/legal/PrivacyPolicy"));
const NotFoundPage = React.lazy(() => import("./pages/error/NotFound"));
const ServerErrorPage = React.lazy(() => import("./pages/error/ServerError"));

const ProtectedRoute: React.FC = () => {
  const { isAuthenticated, isLoading } = useAuth0();

  if (isLoading) {
    return <LoadingScreen />;
  }

  if (!isAuthenticated) {
    return <Navigate to="/login" replace />;
  }

  return <Outlet />;
};

const router = createBrowserRouter(
  [
    {
      path: "/",
      element: <Navigate to="/login" replace />,
    },
    {
      path: "/",
      element: <AuthLayout />,
      children: [
        {
          path: "login",
          element: (
            <React.Suspense fallback={<LoadingScreen />}>
              <LoginPage />
            </React.Suspense>
          ),
        },
        {
          path: "reset-password",
          element: (
            <React.Suspense fallback={<LoadingScreen />}>
              <ResetPasswordPage />
            </React.Suspense>
          ),
        },
      ],
    },
    {
      path: "/app",
      element: <MainLayout />,
      children: [
        {
          element: <ProtectedRoute />,
          children: [
            {
              path: "chat",
              children: [
                {
                  path: "new",
                  element: (
                    <React.Suspense fallback={<LoadingScreen />}>
                      <NewChatPage />
                    </React.Suspense>
                  ),
                },
                {
                  path: ":threadId",
                  element: (
                    <React.Suspense fallback={<LoadingScreen />}>
                      <ChatPage />
                    </React.Suspense>
                  ),
                },
              ],
            },
            {
              path: "settings",
              element: (
                <React.Suspense fallback={<LoadingScreen />}>
                  <SettingsPage />
                </React.Suspense>
              ),
            },
            {
              path: "terms",
              element: (
                <React.Suspense fallback={<LoadingScreen />}>
                  <TermsOfServicePage />
                </React.Suspense>
              ),
            },
            {
              path: "privacy",
              element: (
                <React.Suspense fallback={<LoadingScreen />}>
                  <PrivacyPolicyPage />
                </React.Suspense>
              ),
            },
          ],
        },
      ],
    },
    {
      path: "/500",
      element: (
        <React.Suspense fallback={<LoadingScreen />}>
          <ServerErrorPage />
        </React.Suspense>
      ),
    },
    {
      path: "*",
      element: (
        <React.Suspense fallback={<LoadingScreen />}>
          <NotFoundPage />
        </React.Suspense>
      ),
    },
  ],
  {
    future: {
      v7_relativeSplatPath: true,
      v7_fetcherPersist: true,
      v7_normalizeFormMethod: true,
      v7_partialHydration: true,
      v7_skipActionErrorRevalidation: true,
    },
  }
);

export const AppRouter = () => {
  return <RouterProvider router={router} />;
};

export default AppRouter;