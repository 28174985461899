import { create } from "zustand";
import { devtools, persist } from "zustand/middleware";
import { messageApiService, MessageResponse } from "../services/api";
import { ApiError } from "../types";

interface MessageState {
  messages: MessageResponse[];
  currentMessage: MessageResponse | null;
  loading: "idle" | "loading" | "success" | "error";
  error: ApiError | null;
  sseConnection: EventSource | null;
}

interface MessageActions {
  sendMessage: (message: string, threadId?: string) => Promise<MessageResponse>;
  setCurrentMessage: (message: MessageResponse | null) => void;
  setLoading: (state: MessageState["loading"]) => void;
  setError: (error: ApiError | null) => void;
  clearMessages: () => void;
  initializeSSE: (threadId?: string) => void;
  closeSSE: () => void;
  readAloud: (message: string) => void;
}

interface MessageStore extends MessageState, MessageActions {}

export const useMessageStore = create<MessageStore>()(
  devtools(
    persist(
      (set, get) => ({
        // Initial state
        messages: [],
        currentMessage: null,
        loading: "idle",
        error: null,
        sseConnection: null,

        // Actions
        sendMessage: async (message: string, threadId?: string) => {
          try {
            set({ loading: "loading", error: null });

            const response = await messageApiService.sendMessage({
              message,
              threadId,
            });

            const messageResponse: MessageResponse = {
              data: response.data,
              error: response.error,
            };

            set((state) => ({
              messages: [...state.messages, messageResponse],
              currentMessage: messageResponse,
              loading: "success",
            }));

            return messageResponse;
          } catch (error) {
            const apiError = error as ApiError;
            set({
              error: apiError,
              loading: "error",
            });
            throw error;
          }
        },

        setCurrentMessage: (message: MessageResponse | null) =>
          set({ currentMessage: message }),

        setLoading: (state: MessageState["loading"]) => set({ loading: state }),

        setError: (error: ApiError | null) =>
          set({ error, loading: error ? "error" : "idle" }),

        clearMessages: () =>
          set({
            messages: [],
            currentMessage: null,
            loading: "idle",
            error: null,
            sseConnection: null,
          }),

        initializeSSE: (threadId?: string) => {
          get().closeSSE();
          const sseConnection = messageApiService.createSSEConnection(threadId);

          sseConnection.onmessage = (event) => {
            const message = JSON.parse(event.data) as MessageResponse;
            set((state) => ({
              messages: [...state.messages, message],
              currentMessage: message,
            }));
          };

          sseConnection.onerror = (error) => {
            set({
              error: {
                message: "SSE connection error",
                code: "SSE_ERROR",
                status: 500,
              },
              loading: "error",
            });
            get().closeSSE();
          };

          set({ sseConnection });
        },

        closeSSE: () => {
          const { sseConnection } = get();
          if (sseConnection) {
            sseConnection.close();
            set({ sseConnection: null });
          }
        },

        readAloud: (message: string) => {
          const utterance = new SpeechSynthesisUtterance(message);
          speechSynthesis.speak(utterance);
        },
      }),
      {
        name: "message-storage",
        partialize: (state) => ({
          messages: state.messages,
          currentMessage: state.currentMessage,
        }),
      }
    )
  )
);