import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Box, Typography, Divider } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { motion } from "framer-motion";

const Footer: React.FC = () => {
  const navigate = useNavigate();
  const currentYear = new Date().getFullYear();
  const theme = useTheme();
  const text = "prime AI Sales Assistant";
  const [isExpanded, setIsExpanded] = useState(false);
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isBelow900px = useMediaQuery("(max-width:900px)");

  const handleLogoClick = () => {
    navigate("/app/chat/new");
  };

  const handleMouseEnter = () => {
    if (!isMobile && !isBelow900px) {
      setIsExpanded(true);
      setTimeout(() => {
        const footerElement = document.querySelector("footer");
        if (footerElement) {
          footerElement.scrollIntoView({ behavior: "smooth", block: "end" });
        }
      }, 300);
    }
  };

  const handleMouseLeave = () => {
    if (!isMobile && !isBelow900px) {
      setIsExpanded(false);
    }
  };

  return (
    <Box
      component="footer"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      sx={{
        bgcolor: "background.paper",
        mt: "auto",
        py: isExpanded ? 4 : 1,
        px: isMobile ? 0 : 2,
        height: isExpanded ? "auto" : "50px",
        transition: "all 0.3s ease-in-out",
        overflow: "hidden",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        position: isMobile ? "relative" : "fixed",
        bottom: 0,
        left: 0,
        right: 0,
        zIndex: 10,
        maxHeight: isExpanded ? "50%" : "50px",
        overflowY: isExpanded ? "auto" : "hidden",
      }}
    >
      {!isExpanded && (
        <Typography variant="body2" color="textSecondary" align="center">
          © {currentYear} CPrime Inc. All rights reserved.
        </Typography>
      )}

      {isExpanded && (
        <>
          <Box
            sx={{
              maxWidth: "1120px",
              mx: "auto",
              display: "grid",
              gridTemplateColumns: {
                xs: "1fr",
                sm: "repeat(2, 1fr)",
                md: "repeat(4, 1fr)",
              },
              gap: isMobile ? 2 : 4,
            }}
          >
            <Box>
              <h1
                className="text-xl font-[500] mb-2 text-gray-600 cursor-pointer"
                style={{ fontFamily: "Barlow Semi Condensed" }}
                onClick={handleLogoClick}
              >
                <motion.span
                  className="text-gray-400 inline-block"
                  initial={{ scale: 0, opacity: 0 }}
                  animate={{ scale: 1, opacity: 1 }}
                  transition={{
                    type: "spring",
                    stiffness: 260,
                    damping: 20,
                  }}
                >
                  C
                </motion.span>
                {text.split("").map((char, index) => (
                  <motion.span
                    key={index}
                    initial={{ opacity: 0, x: -10 }}
                    animate={{ opacity: 1, x: 0 }}
                    transition={{
                      duration: 0.1,
                      delay: 0.3 + index * 0.05,
                      ease: "easeOut",
                    }}
                  >
                    {char}
                  </motion.span>
                ))}
              </h1>
              <Typography variant="body2" color="textSecondary">
                Empowering teams to deliver value through innovation and
                transformation.
              </Typography>
            </Box>

            <Box>
              <Typography variant="h6" sx={{ mb: 2 }}>
                Quick Links
              </Typography>
              <Box component="ul" sx={{ listStyle: "none", p: 0, m: 0 }}>
                <Box component="li" sx={{ mb: 1 }}>
                  <Link
                    to="/app/chat/new"
                    style={{
                      color: theme.palette.text.secondary,
                      textDecoration: "none",
                      cursor: "pointer",
                      padding: "8px 0",
                      display: "block",
                    }}
                  >
                    Chat
                  </Link>
                </Box>
                <Box component="li" sx={{ mb: 1 }}>
                  <Link
                    to="/app/settings"
                    style={{
                      color: theme.palette.text.secondary,
                      textDecoration: "none",
                      cursor: "pointer",
                      padding: "8px 0",
                      display: "block",
                    }}
                  >
                    Settings
                  </Link>
                </Box>
              </Box>
            </Box>

            <Box>
              <Typography variant="h6" sx={{ mb: 1 }}>
                Support
              </Typography>
              <Box component="ul" sx={{ listStyle: "none", p: 0, m: 0 }}>
                <Box component="li" sx={{ mb: 0.5 }}>
                  <a
                    href="mailto:tech-support@cprime.com"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{
                      color: theme.palette.text.secondary,
                      textDecoration: "none",
                      cursor: "pointer",
                      padding: "8px 0",
                      display: "block",
                    }}
                  >
                    Technical Support
                  </a>
                </Box>
                <Box component="li" sx={{ mb: 0.5 }}>
                  <a
                    href="mailto:sales-support@cprime.com"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{
                      color: theme.palette.text.secondary,
                      textDecoration: "none",
                      cursor: "pointer",
                      padding: "8px 0",
                      display: "block",
                    }}
                  >
                    Sales Support
                  </a>
                </Box>
                <Box component="li" sx={{ mb: 0.5 }}>
                  <a
                    href="https://www.cprime.com/support"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{
                      color: theme.palette.text.secondary,
                      textDecoration: "none",
                      cursor: "pointer",
                      padding: "8px 0",
                      display: "block",
                    }}
                  >
                    Help Center
                  </a>
                </Box>
              </Box>
            </Box>

            <Box>
              <Typography variant="h6" sx={{ mb: 1 }}>
                Legal
              </Typography>
              <Box component="ul" sx={{ listStyle: "none", p: 0, m: 0 }}>
                <Box component="li" sx={{ mb: 0.5 }}>
                  <Link
                    to="/app/privacy"
                    style={{
                      color: theme.palette.text.secondary,
                      textDecoration: "none",
                      cursor: "pointer",
                      padding: "8px 0",
                      display: "block",
                    }}
                  >
                    Privacy Policy
                  </Link>
                </Box>
                <Box component="li" sx={{ mb: 0.5 }}>
                  <Link
                    to="/app/terms"
                    style={{
                      color: theme.palette.text.secondary,
                      textDecoration: "none",
                      cursor: "pointer",
                      padding: "8px 0",
                      display: "block",
                    }}
                  >
                    Terms of Service
                  </Link>
                </Box>
              </Box>
            </Box>
          </Box>

          <Divider sx={{ mt: 4, mb: 4, width: "100%" }} />

          <Typography variant="body2" color="textSecondary" align="center">
            © {currentYear} CPrime Inc. All rights reserved.
          </Typography>
        </>
      )}
    </Box>
  );
};

export default Footer;
