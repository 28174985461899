import React, { useState, useEffect } from "react";
import { Alert, Snackbar } from "@mui/material";
import { Wifi, WifiOff } from "lucide-react";

const NetworkStatus = () => {
  const [isOnline, setIsOnline] = useState(navigator.onLine);
  const [showOnlineNotice, setShowOnlineNotice] = useState(false);

  useEffect(() => {
    const handleOnline = () => {
      setIsOnline(true);
      setShowOnlineNotice(true);
    };
    const handleOffline = () => setIsOnline(false);

    window.addEventListener("online", handleOnline);
    window.addEventListener("offline", handleOffline);

    return () => {
      window.removeEventListener("online", handleOnline);
      window.removeEventListener("offline", handleOffline);
    };
  }, []);

  return (
    <>
      <Snackbar
        open={!isOnline}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert severity="warning" icon={<WifiOff size={24} />}>
          You are offline. Check your internet connection.
        </Alert>
      </Snackbar>

      <Snackbar
        open={showOnlineNotice}
        autoHideDuration={2500}
        onClose={() => setShowOnlineNotice(false)}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert severity="success" icon={<Wifi size={24} />}>
          You are back online!
        </Alert>
      </Snackbar>
    </>
  );
};

export default NetworkStatus;
