import React, { useState, useEffect } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import {
  Menu,
  MenuItem,
  ListItemText,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  IconButton,
  Box,
  useTheme,
  LinearProgress,
} from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Plus, X, ArrowDownWideNarrow, ArrowUpWideNarrow } from "lucide-react";
import { Thread } from "../../types/thread";
import { useThreadStore } from "../../store/threadStore";
import { useMessageStore } from "../../store/messageStore";
import { formatDistanceToNow } from "date-fns";
import { toast } from "react-toastify";
import { useUserStore } from "../../store/userStore";
import { motion } from "framer-motion";

interface ThreadsListProps {
  anchorEl: HTMLElement | null;
  onClose: () => void;
}

const ThreadsList: React.FC<ThreadsListProps> = ({ anchorEl, onClose }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();
  const { threadId } = useParams();
  const userId = useUserStore((state) => state.user?._id);
  const {
    threads = [],
    setCurrentThread,
    fetchThreads,
    deleteThread,
  } = useThreadStore();
  const { clearMessages } = useMessageStore();

  const isNewChatRoute = location.pathname === "/app/chat/new";

  // State for delete confirmation modal
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [threadToDelete, setThreadToDelete] = useState<Thread | null>(null);
  const [sortDescending, setSortDescending] = useState(true);
  const [isFetching, setIsFetching] = useState(false);

  // Filter threads by current user
  const userThreads = threads.filter((thread) => thread.userId === userId);

  const sortThreads = (threads: Thread[]) => {
    return [...threads].sort((a, b) => {
      const dateA = new Date(a.updatedAt).getTime();
      const dateB = new Date(b.updatedAt).getTime();
      return sortDescending ? dateB - dateA : dateA - dateB;
    });
  };

  useEffect(() => {
    if (anchorEl) {
      setIsFetching(true);
      fetchThreads().finally(() => setIsFetching(false));
    }
  }, [anchorEl, fetchThreads]);

  const handleChatSelect = (thread: Thread) => {
    if (thread.id !== threadId) {
      onClose();
      setCurrentThread(thread);
      navigate(`/app/chat/${thread.id}`);
    } else {
      onClose();
    }
  };

  const handleNewChat = () => {
    if (isNewChatRoute) return;
    onClose();
    setCurrentThread(null);
    navigate("/app/chat/new");
    clearMessages();
    setTimeout(() => {
      document.querySelector<HTMLTextAreaElement>(".chat-input")?.focus();
      toast.info("Send your first message to start a new thread", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }, 100);
  };

  const handleDeleteClick = (event: React.MouseEvent, thread: Thread) => {
    event.stopPropagation();
    setThreadToDelete(thread);
    setDeleteModalOpen(true);
  };

  const handleDeleteConfirm = async () => {
    if (!threadToDelete) return;

    try {
      await deleteThread(threadToDelete.id);
      setDeleteModalOpen(false);
      toast.success("Thread deleted successfully");

      // Handle navigation after deletion
      if (threadToDelete.id === threadId) {
        // If we deleted the active thread
        if (userThreads.length <= 1) {
          // If this was the last thread, redirect to new chat
          navigate("/app/chat/new");
          setCurrentThread(null);
        } else {
          // Find the next available thread
          const nextThread = userThreads.find(
            (t) => t.id !== threadToDelete.id
          );
          if (nextThread) {
            navigate(`/app/chat/${nextThread.id}`);
            setCurrentThread(nextThread);
          }
        }
      }
    } catch (error) {
      toast.error("Failed to delete thread");
    }
  };

  const getLastMessageTime = (thread: Thread) => {
    if (!thread?.messages?.length) {
      return "No messages";
    }
    return formatDistanceToNow(new Date(thread.updatedAt), { addSuffix: true });
  };

  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const truncateTitle = (title: string) => {
    const maxLength = isMobile ? 35 : 50;
    if (title.length > maxLength) {
      return title.substring(0, maxLength) + "...";
    }
    return title;
  };

  const ThreadDot = ({ isActive }: { isActive: boolean }) => (
    <Box
      component="span"
      sx={{
        width: 6,
        height: 6,
        borderRadius: "50%",
        backgroundColor: isActive ? theme.palette.text.primary : "transparent",
        display: "inline-block",
        marginRight: 1,
        marginLeft: 0,
        position: "relative",
        top: "-1px",
      }}
    />
  );

  return (
    <>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={onClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        slotProps={{
          paper: {
            style: {
              maxHeight: isMobile ? "50vh" : "40vh",
              minWidth: isMobile ? "70vw" : "200px",
              overflow: "auto",
              margin: "0 auto",
            },
          },
        }}
      >
        <div className="flex justify-between items-center px-3 py-2 border-b border-gray-200 dark:border-gray-700">
          <span className="text-sm text-gray-500 dark:text-gray-400">
            Sort by date: {sortDescending ? "Newest first" : "Oldest first"}
          </span>
          <IconButton
            size="small"
            onClick={() => setSortDescending(!sortDescending)}
            className="text-gray-500 hover:text-blue-500 transition-colors"
            title={
              sortDescending
                ? "Change to oldest messages first"
                : "Change to newest messages first"
            }
          >
            {sortDescending ? (
              <ArrowDownWideNarrow size={16} />
            ) : (
              <ArrowUpWideNarrow size={16} />
            )}
          </IconButton>
        </div>

        {isFetching ? (
          <Box sx={{ width: '100%' }}>
            <LinearProgress />
          </Box>
        ) : (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.3 }}
          >
            <MenuItem
              onClick={handleNewChat}
              className={`text-blue-600 ${isNewChatRoute ? "opacity-50" : ""}`}
              disabled={isNewChatRoute}
              title={
                isNewChatRoute ? "Already on new thread" : "Start a new thread"
              }
            >
              <Plus size={16} className="mr-2" />
              New Thread
            </MenuItem>

            {userThreads.length === 0 ? (
              <MenuItem disabled className="text-gray-500 min-w-[200px]">
                <ListItemText
                  primary="No threads yet"
                  secondary="Start a new conversation"
                />
              </MenuItem>
            ) : (
              sortThreads(userThreads).map((thread) => (
                <MenuItem
                  key={thread.id}
                  onClick={() => handleChatSelect(thread)}
                  selected={thread.id === threadId}
                  className="min-w-[200px] relative group"
                  title={thread.title || "Untitled Thread"}
                  sx={{
                    "&:hover .delete-button": {
                      opacity: 1,
                    },
                    "&.Mui-selected": {
                      backgroundColor: theme.palette.action.selected,
                    },
                  }}
                >
                  <Box sx={{ display: "flex", alignItems: "center", flex: 1 }}>
                    <ThreadDot isActive={thread.id === threadId} />
                    <Box sx={{ flex: 1 }}>
                      <ListItemText
                        primary={truncateTitle(
                          thread.title || "Untitled Thread"
                        )}
                        secondary={`Last updated ${getLastMessageTime(thread)}`}
                        primaryTypographyProps={{
                          color:
                            thread.id === threadId
                              ? "textPrimary"
                              : "textSecondary",
                          noWrap: true,
                        }}
                      />
                    </Box>
                    <IconButton
                      size="small"
                      onClick={(e) => handleDeleteClick(e, thread)}
                      className="delete-button text-gray-400 hover:text-red-500 transition-all opacity-0"
                      sx={{
                        padding: "4px",
                        "&:hover": {
                          backgroundColor: "rgba(239, 68, 68, 0.1)",
                        },
                      }}
                    >
                      <X size={16} />
                    </IconButton>
                  </Box>
                </MenuItem>
              ))
            )}
          </motion.div>
        )}
      </Menu>

      <Dialog
        open={deleteModalOpen}
        onClose={() => setDeleteModalOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Delete Thread?"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this thread? This action cannot be
            undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setDeleteModalOpen(false)}
            sx={{
              color: (theme) =>
                theme.palette.mode === "dark" ? "#ccc" : "#555",
              backgroundColor: (theme) =>
                theme.palette.mode === "dark" ? "#333" : "rgba(0, 0, 0, 0.04)",
              "&:hover": {
                color: (theme) =>
                  theme.palette.mode === "dark" ? "#fff" : "#000",
                backgroundColor: (theme) =>
                  theme.palette.mode === "dark"
                    ? "#444"
                    : "rgba(0, 0, 0, 0.08)",
              },
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={handleDeleteConfirm}
            sx={{
              backgroundColor: "#ed1854",
              color: "#fff",
              "&:hover": {
                backgroundColor: "#d41549",
              },
            }}
            autoFocus
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ThreadsList;
