import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
  AppBar,
  Toolbar,
  IconButton,
  Avatar,
  Menu,
  MenuItem,
  Typography,
  useTheme,
  Backdrop,
  Button,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  CircularProgress,
  DialogContentText,
} from "@mui/material";
import {
  Settings,
  LogOut,
  MessageCircle,
  Plus,
  Menu as MenuIcon,
} from "lucide-react";
import { useUserStore } from "../../store/userStore";
import { useThreadStore } from "../../store/threadStore";
import { apiServices } from "../../services/api";
import ThreadsList from "../../components/ThreadsList/ThreadsList";
import { useAuth0 } from "@auth0/auth0-react";
import useMediaQuery from "@mui/material/useMediaQuery";

interface HeaderProps {
  children?: React.ReactNode;
}

const Header: React.FC<HeaderProps> = ({ children }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const theme = useTheme();
  const logout = useUserStore((state) => state.logout);
  const { logout: auth0Logout, user: auth0User } = useAuth0();
  const { fetchThreads } = useThreadStore();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [mobileOpen, setMobileOpen] = useState(false);
  const [isLoggingOut, setIsLoggingOut] = useState(false);

  const [profileAnchorEl, setProfileAnchorEl] =
    React.useState<HTMLElement | null>(null);
  const [chatAnchorEl, setChatAnchorEl] = React.useState<HTMLElement | null>(
    null
  );

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const isActiveRoute = (path: string) => {
    return (
      location.pathname === path || location.pathname.startsWith(`${path}/`)
    );
  };

  const getUserInitials = () => {
    if (!auth0User) return "?";
    const firstName =
      auth0User.given_name || auth0User.name?.split(" ")[0] || "";
    const lastName =
      auth0User.family_name || auth0User.name?.split(" ")[1] || "";
    return `${firstName.charAt(0)}${lastName.charAt(0)}`.toUpperCase();
  };

  const handleChatMenu = async (event: React.MouseEvent<HTMLElement>) => {
    setChatAnchorEl(event.currentTarget);
    await fetchThreads();
  };

  const handleNewChat = () => {
    if (!isActiveRoute("/app/chat/new")) {
      navigate("/app/chat/new");
      handleCloseProfile();
      setMobileOpen(false);
    }
  };

  const handleLogoClick = () => {
    navigate("/app/chat/new");
  };

  const handleProfileMenu = (event: React.MouseEvent<HTMLElement>) => {
    setProfileAnchorEl(event.currentTarget);
  };

  const handleCloseProfile = () => setProfileAnchorEl(null);
  const handleCloseChat = () => setChatAnchorEl(null);

  const handleLogout = async () => {
    try {
      setIsLoggingOut(true);
      handleCloseProfile();

      apiServices.user.signOut();

      logout();

      await auth0Logout({
        logoutParams: {
          returnTo: window.location.origin,
        },
      });

      setTimeout(() => {
        navigate("/login", { replace: true });
        setIsLoggingOut(false);
      }, 1000);
    } catch (error) {
      console.error("Logout failed:", error);
      navigate("/login", { replace: true });
      setIsLoggingOut(false);
    }
  };

  const handleSettings = () => {
    handleCloseProfile();
    navigate("/app/settings");
    setMobileOpen(false);
  };

  const getMenuItemClass = (path?: string) => {
    const baseClass = "flex items-center gap-3 px-4 py-3 text-sm";
    if (!path) return baseClass;

    const isActive = isActiveRoute(path);
    const isDisabled = path === "/app/chat/new" && isActive;

    return `${baseClass} ${
      isActive
        ? "bg-blue-500/20 text-blue-400"
        : "hover:bg-gray-100/10 text-white"
    } ${isDisabled ? "opacity-50 cursor-not-allowed" : ""}`;
  };

  const menuIconClass = "w-5 h-5";

  return (
    <>
      <AppBar
        position="fixed"
        className={theme.palette.mode === "dark" ? "bg-gray-900" : "bg-white"}
        elevation={1}
      >
        <Toolbar className="flex justify-between items-center">
          <div
            className="flex items-center cursor-pointer"
            onClick={handleLogoClick}
          >
            {children}
          </div>

          {isMobile ? (
            <div className="flex items-center">
              <IconButton onClick={handleChatMenu}>
                <MessageCircle size={20} />
              </IconButton>
              <IconButton onClick={handleDrawerToggle} className="ml-2">
                <MenuIcon />
              </IconButton>
            </div>
          ) : (
            <div className="flex items-center gap-4">
              <Button
                color="inherit"
                onClick={handleChatMenu}
                className={
                  theme.palette.mode === "dark"
                    ? "text-gray-300"
                    : "text-gray-700"
                }
                startIcon={<MessageCircle size={20} />}
              >
                Threads
              </Button>

              <IconButton onClick={handleProfileMenu} className="ml-2">
                <Avatar className="bg-blue-600">{getUserInitials()}</Avatar>
              </IconButton>
            </div>
          )}
        </Toolbar>
      </AppBar>

      <ThreadsList anchorEl={chatAnchorEl} onClose={handleCloseChat} />

      <Backdrop
        open={Boolean(profileAnchorEl)}
        onClick={handleCloseProfile}
        className="bg-gray-900/50 backdrop-blur-sm z-[1200]"
      />

      <Menu
        anchorEl={profileAnchorEl}
        open={Boolean(profileAnchorEl)}
        onClose={handleCloseProfile}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        className="mt-2"
        slotProps={{
          paper: {
            elevation: 0,
            className:
              "bg-gray-800/95 backdrop-blur-sm border border-gray-700 rounded-lg overflow-hidden min-w-[240px]",
          },
        }}
      >
        {auth0User && (
          <div className="px-4 py-3 border-b border-gray-700">
            <Typography
              className="font-medium text-base"
              sx={{
                color:
                  theme.palette.mode === "light"
                    ? theme.palette.grey[900]
                    : theme.palette.common.white,
              }}
            >
              {auth0User.name}
            </Typography>
            <Typography
              className="text-sm"
              sx={{
                color:
                  theme.palette.mode === "light"
                    ? theme.palette.grey[700]
                    : theme.palette.grey[400],
              }}
            >
              {auth0User.email}
            </Typography>
          </div>
        )}
        <MenuItem
          onClick={handleNewChat}
          disabled={isActiveRoute("/app/chat/new")}
          className={getMenuItemClass("/app/chat/new")}
        >
          <Plus
            className={`${menuIconClass} ${
              isActiveRoute("/app/chat/new") ? "text-blue-400" : ""
            }`}
          />
          <span>New Chat</span>
        </MenuItem>
        <MenuItem
          onClick={handleSettings}
          className={getMenuItemClass("/app/settings")}
        >
          <Settings
            className={`${menuIconClass} ${
              isActiveRoute("/app/settings") ? "text-blue-400" : ""
            }`}
          />
          <span>Settings</span>
        </MenuItem>
        <MenuItem
          onClick={handleLogout}
          className="flex items-center gap-3 px-4 py-3 text-sm text-red-400 hover:bg-gray-100/10"
        >
          <LogOut className={menuIconClass} />
          <span>Logout</span>
        </MenuItem>
      </Menu>

      <Drawer
        anchor="right"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true,
        }}
      >
        <Box
          sx={{
            width: 250,
            display: "flex",
            flexDirection: "column",
            height: "100%",
            justifyContent: "space-between",
          }}
          role="presentation"
          onClick={handleCloseProfile}
        >
          <Box>
            {auth0User && (
              <Box sx={{ p: 2, bgcolor: theme.palette.background.paper }}>
                <Typography variant="subtitle1">{auth0User.name}</Typography>
                <Typography variant="body2" color="textSecondary">
                  {auth0User.email}
                </Typography>
              </Box>
            )}
            <Divider />
            <List>
              <ListItem button onClick={handleNewChat}>
                <ListItemIcon>
                  <Plus size={20} />
                </ListItemIcon>
                <ListItemText primary="New Chat" />
              </ListItem>
              <ListItem button onClick={handleSettings}>
                <ListItemIcon>
                  <Settings size={20} />
                </ListItemIcon>
                <ListItemText primary="Settings" />
              </ListItem>
              <ListItem button onClick={handleLogout}>
                <ListItemIcon>
                  <LogOut size={20} />
                </ListItemIcon>
                <ListItemText primary="Logout" />
              </ListItem>
            </List>
          </Box>
          <Box>
            <Divider />
            <List>
              <ListItem
                button
                onClick={() => {
                  navigate("/app/privacy");
                  setMobileOpen(false);
                }}
              >
                <ListItemText primary="Privacy Policy" />
              </ListItem>
              <ListItem
                button
                onClick={() => {
                  navigate("/app/terms");
                  setMobileOpen(false);
                }}
              >
                <ListItemText primary="Terms of Service" />
              </ListItem>
            </List>
          </Box>
        </Box>
      </Drawer>

      <Dialog
        open={isLoggingOut}
        aria-labelledby="logout-dialog-title"
        aria-describedby="logout-dialog-description"
      >
        <DialogTitle id="logout-dialog-title">Logging Out</DialogTitle>
        <DialogContent>
          <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
            <CircularProgress size={24} />
            <DialogContentText id="logout-dialog-description">
              Please wait while we log you out...
            </DialogContentText>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default Header;
