// src/utils/errorHandling.ts
import { toast } from "react-toastify";

export interface ErrorDetails {
  code: string;
  message: string;
  status?: number;
  field?: string;
}

export class ApplicationError extends Error {
  code: string;
  status?: number;
  field?: string;

  constructor(details: ErrorDetails) {
    super(details.message);
    this.code = details.code;
    this.status = details.status;
    this.field = details.field;
  }
}

// Error code to user-friendly message mapping
const ERROR_MESSAGES: Record<string, string> = {
  // Authentication errors
  TOKEN_EXPIRED: "Your session has expired. Please log in again.",
  INVALID_CREDENTIALS: "Invalid email or password.",
  UNAUTHORIZED: "You are not authorized to perform this action.",

  // File handling errors
  FILE_TOO_LARGE: "The file size exceeds the maximum limit.",
  INVALID_FILE_TYPE: "This file type is not supported.",
  FILE_UPLOAD_FAILED: "Failed to upload the file. Please try again.",

  // Network errors
  NETWORK_ERROR:
    "Unable to connect to the server. Please check your internet connection.",
  REQUEST_TIMEOUT: "The request timed out. Please try again.",
  SERVER_ERROR: "An unexpected server error occurred. Please try again later.",

  // API errors
  RATE_LIMIT_EXCEEDED: "Too many requests. Please try again later.",
  RESOURCE_NOT_FOUND: "The requested resource was not found.",
  VALIDATION_ERROR: "Please check your input and try again.",

  // Default error
  UNKNOWN_ERROR: "An unexpected error occurred. Please try again.",
};

export const handleError = (error: any): ErrorDetails => {
  // If it's already our ApplicationError, use it directly
  if (error instanceof ApplicationError) {
    return {
      code: error.code,
      message: ERROR_MESSAGES[error.code] || error.message,
      status: error.status,
      field: error.field,
    };
  }

  // Handle Axios errors
  if (error.isAxiosError) {
    const status = error.response?.status;
    const apiError = error.response?.data;

    // Network error (no response received)
    if (!error.response) {
      return {
        code: "NETWORK_ERROR",
        message: ERROR_MESSAGES["NETWORK_ERROR"],
        status: 0,
      };
    }

    // Handle specific HTTP status codes
    switch (status) {
      case 401:
        return {
          code: "UNAUTHORIZED",
          message: ERROR_MESSAGES["UNAUTHORIZED"],
          status: 401,
        };
      case 403:
        return {
          code: "FORBIDDEN",
          message: ERROR_MESSAGES["UNAUTHORIZED"],
          status: 403,
        };
      case 404:
        return {
          code: "RESOURCE_NOT_FOUND",
          message: ERROR_MESSAGES["RESOURCE_NOT_FOUND"],
          status: 404,
        };
      case 429:
        return {
          code: "RATE_LIMIT_EXCEEDED",
          message: ERROR_MESSAGES["RATE_LIMIT_EXCEEDED"],
          status: 429,
        };
      case 500:
        return {
          code: "SERVER_ERROR",
          message: ERROR_MESSAGES["SERVER_ERROR"],
          status: 500,
        };
    }

    // Use API provided error if available
    if (apiError) {
      return {
        code: apiError.code || "API_ERROR",
        message: ERROR_MESSAGES[apiError.code] || apiError.message,
        status: status,
        field: apiError.field,
      };
    }
  }

  // Handle file-related errors
  if (error instanceof File || error.name === "File") {
    if (error.size > 10 * 1024 * 1024) {
      // 10MB limit example
      return {
        code: "FILE_TOO_LARGE",
        message: ERROR_MESSAGES["FILE_TOO_LARGE"],
      };
    }
  }

  // Default unknown error
  return {
    code: "UNKNOWN_ERROR",
    message: ERROR_MESSAGES["UNKNOWN_ERROR"],
    status: 500,
  };
};

// Utility function to show error toast
export const showErrorToast = (error: any) => {
  const errorDetails = handleError(error);
  toast.error(errorDetails.message, {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
  });
  return errorDetails;
};

// Utility function for form field errors
export const getFieldError = (
  error: any,
  fieldName: string
): string | undefined => {
  const errorDetails = handleError(error);
  if (errorDetails.field === fieldName) {
    return errorDetails.message;
  }
  return undefined;
};
